.hero {
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;

	@media only screen and (max-width: 700px) {
		height: 90vh;
		overflow: hidden;
	}

	.swiper-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1; // Base layer
	}

	.hero-swiper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1; // Base layer

		.swiper-slide {
			width: 100%;
			height: 100%;
		}
	}

	.homebanner {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 100%;

		img,
		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.hero_container {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;

		#hero_video {
			position: absolute;
			right: 0;
			bottom: 0;
			min-width: 100%;
			min-height: 100%;

			@media only screen and (max-width: 380px) {
				height: 100vh;
			}

			@media (max-width: 500px) {
				object-fit: cover;
				position: relative !important;
			}
		}

		.video_filter {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.4);
			z-index: 2; // Above swiper but below content
			pointer-events: none; // This allows clicks to pass through to the swiper
		}

		.hero_content {
			position: relative; // Changed from absolute
			width: 65%;
			z-index: 3; // Above the overlay

			@media only screen and (max-width: 1024px) {
				width: 100%;
			}

			.hero_sub_title {
				position: relative;
				margin-bottom: 1rem;
			}

			.hero_sub_title::after {
				content: "";
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
				height: 1px;
				width: 32px;
				background-color: #005f9e;
			}

			h1 {
				margin: 24px 0;
			}

			.detail {
				margin: 24px 0;
				word-wrap: break-word;
			}

			.detail-new {
				margin: 24px 0;
				word-wrap: break-word;
				font-family: "Optima", sans-serif;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.48px;
				text-transform: uppercase;
				line-height: 1.2;
			}

			p {
				margin-bottom: 24px;

				@media only screen and (max-width: 700px) {
					margin: 2rem 0;
				}
			}
		}
	}

	.hero_container {
		.slider_btn {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 40px;
			z-index: 10;
			max-width: 52px;
			cursor: pointer;
			
			&.leftbtn {
				left: 40px;
				right: auto;
			}
			
			@media (max-width: 700px) {
				max-width: 40px;
				display: none; /* Hide on mobile */
			}
		}
		
		.swiper-pagination {
			position: absolute;
			bottom: 20px;
			width: 100%;
			display: flex;
			justify-content: center;
			z-index: 10;
			
			.swiper-pagination-bullet {
				width: 24px;
				height: 3px;
				background-color: rgba(255, 255, 255, 0.5);
				margin: 0 5px;
				border-radius: 0;
				cursor: pointer;
				transition: all 0.3s ease;
				
				&-active {
					background-color: #fff;
					width: 32px; /* Make active bullet slightly longer */
				}
			}
		}
	}
}

.hero_content>h1:first-child {
	font-size: 92px;

	@media only screen and (max-width: 700px) {
		font-size: 48px;
	}
}