.new_layout {
  @media only screen and (max-width: 700px) {
    padding: 0;
  }
}
.testimonial {
  position: relative;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 1200px) {
    margin: 2rem;
    padding: 0;
  }
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    height: 100%;
  }
  @media only screen and (max-width: 700px) {
    margin: 2rem 0;
  }

  .testimonial_content {
    @media only screen and (max-width: 700px) {
      padding: 0 1rem;
    }
    h2 {
      position: relative;
    }
    h2::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      height: 2px;
      width: 42px;
      background-color: #005f9e;
    }
    p {
      font-size: 16px;
      margin-top: 24px;
      text-align: justify;
    }
  }
  & > div {
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 1000px) {
      align-items: center;
      width: 100%;
    }
  }
  .video_container {
    // position: relative;
    overflow: hidden;
    // z-index: 1;
    .testa_video {
      max-width: 420px;
      width: 100%;
      height: 675px;
      object-fit: contain;
      float: right;
      position: relative;
      transition: all 0.5s ease-in-out;
      @media only screen and (max-width: 1000px) {
        position: relative;
        margin-top: 4rem;
      }
    }
    // .testa_video:hover {
    //   transform: scale(1.2);
    //   @media only screen and (max-width: 1000px) {
    //     transform: scale(1);
    //   }
    // }
  }
}
